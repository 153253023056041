.form-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    max-width: 500px;
    margin: 0 auto;
    font-family: Arial, sans-serif;
}

.form-title {
    text-align: center;
    font-size: 1.8rem;
    margin-bottom: 20px;
}

.form-input,
.form-textarea {
    width: 100%;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
    font-size: 1rem;
    margin-bottom: 15px;
    box-sizing: border-box;
    transition: border 0.3s;
    margin-top: 5px;
}

.form-textarea {
    height: 120px;
}

.form-button {
    padding: 10px 20px;
    background-color: #4CAF50;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s;
    width: 100%;
}

.form-button:hover {
    background-color: #45a049;
}

.form-label {
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 5px;
}

.announcement-page {
    padding: 20px;
}

.announcement-list {
    margin-top: 20px;
}

h3 {
    font-size: 1.5rem;
    margin-bottom: 10px;
}

.form-container {
    margin-bottom: 20px;
    padding: 15px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.form-title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
}

.form-label {
    font-size: 14px;
    font-weight: bold;
}

.form-input,
.form-textarea {
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
    border-radius: 4px;
    border: 1px solid #ddd;
}

.form-button {
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
}

.form-button:hover {
    background-color: #45a049;
}

.add-button {
    background-color: #008CBA;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 4px;
    cursor: pointer;
    margin-bottom: 20px;
}

.add-button:hover {
    background-color: #007bb5;
}

.existing-announcements {
    margin-top: 20px;
}

.announcement-card {
    background-color: #f1f1f1;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
}

.delete-button {
    background-color: #e74c3c;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 4px;
    cursor: pointer;
}

.delete-button:hover {
    background-color: #c0392b;
}

.edit-button {
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 5px 10px;
    margin: 5px 5px;
    cursor: pointer;
    border-radius: 5px;
    font-weight: bold;
    transition: background-color 0.3s ease;
}

.edit-button:hover {
    background-color: #45a049;
}

.delete-button {
    background-color: #f44336;
    color: white;
    border: none;
    padding: 5px 10px;
    margin: 5px 0;
    cursor: pointer;
    border-radius: 5px;
    font-weight: bold;
    transition: background-color 0.3s ease;
}

.delete-button:hover {
    background-color: #e53935;
}

.order-field {
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.order-field label {
    margin-right: 10px;
}

.order-input {
    width: 40px;
    padding: 3px;
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.order-input:focus {
    border-color: #4CAF50;
}

.existing-announcements {
    margin-top: 20px;
}

.announcement-card {
    background-color: #f9f9f9;
    padding: 15px;
    margin-bottom: 15px;
    border-radius: 5px;
    border: 1px solid #ddd;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.announcement-card h3 {
    margin: 0 0 10px 0;
}

.announcement-card p {
    margin: 5px 0;
}

.add-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
    font-size: 16px;
    font-weight: bold;
    transition: background-color 0.3s ease;
}

.add-button:hover {
    background-color: #0056b3;
}

.form-container {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.form-title {
    font-size: 24px;
    margin-bottom: 20px;
    font-weight: bold;
}

.form-label {
    display: block;
    margin-bottom: 5px;
}

.form-input,
.form-textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
}

.form-button {
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
    font-weight: bold;
}

.form-button:hover {
    background-color: #45a049;
}

.form-input {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
}

.form-label {
    display: block;
    margin-bottom: 5px;
}

.form-input[type="date"] {
    cursor: pointer;
}

.form-textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
}

.create-announcement-button {
    background-color: #28a745;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    font-size: 1rem;
    font-weight: bold;
    cursor: pointer;
    width: 100%;
    transition: background-color 0.3s;
}

.create-announcement-button:hover {
    background-color: #218838;
}

.cancel-button {
    background-color: #dc3545;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    font-size: 1rem;
    font-weight: bold;
    cursor: pointer;
    width: 100%;
    transition: background-color 0.3s;
}

.cancel-button:hover {
    background-color: #c82333;
}

.save-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    font-size: 1rem;
    font-weight: bold;
    cursor: pointer;
    width: 100%;
    transition: background-color 0.3s;
}

.save-button:hover {
    background-color: #0056b3;
}

.error-message {
    color: red;
    font-size: 1.2rem;
    margin-top: 0.25rem;
}

.enable-link-checkbox {
    text-align: left;
}

.button-container {
    display: flex;
    justify-content: space-between;
    gap: 10px;
}

.button-container button {
    flex: 1;
    padding: 12px;
}

.enable-link-checkbox input {
    margin-top: 20px;
    margin-left: 20px;
    width: auto;
}

.buttons-container {
    display: flex;
    gap: 10px;
}

.done-button {
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 4px;
    cursor: pointer;
    margin-bottom: 20px;
}

.done-button:hover {
    background-color: #45a049;
}
